<template lang="pug">
div
  .step-subtitle.m-t-20 {{ $t("info.financialFormTitle") }}
  ul.model-char-list.m-t-10
    li.model-char-list-item
      span {{ $t("info.finForm") }}
      span {{ $t(priceForm) }}
    li.model-char-list-item
      span {{ $t("info.contractDuration") }}
      span {{ month }} {{ $t("months") }}
    li.model-char-list-item(v-if="distance > 0")
      span {{ $t("info.mileage") }}
      span {{ distance }} {{distanceTitle}}
    //- li.model-char-list-item(v-if="overMilesPrice > 0")
    //-   span {{ $t("info.overmiles") }}
    //-   span {{ overMilesPrice }} {{ currencySymbol }}

    template(v-if="country.code === 'dk'")
      li.model-char-list-item
        span {{ $t("info.cashDeposit") }}
        span {{ formatPrice(downPaymentDefault) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.totalLeasingCost") }}
        span {{ formatPrice(totalLeasePayment) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.taxFee") }}
        span {{ formatPrice(halfYearTaxFee) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.deliveryCost") }}
        span {{ formatPrice(deliveryCost) }} {{ currencySymbol }}

  .step-subtitle.m-t-20 {{ $t("info.serviceTitle") }}
  ul.model-char-list.m-t-10
    li.model-char-list-item
      span {{ $t("info.service") }}
      span {{ service ? $t("info.included") : $t("info.notIncluded") }}
    li.model-char-list-item(v-if="vehicle.roadsideAssist && Object.keys(vehicle.roadsideAssist).length")
      span {{ $t("info.roadsideAssistance") }}
      span {{ vehicle.roadsideAssist.name }}
  .step-subtitle.m-t-20 {{ $t("info.deliveryTitle") }}
  ul.model-char-list.m-t-10
    li.model-char-list-item(v-if="isRental(this.priceForm)")
      span {{ $t("info.pickupDateTime") }}
      span {{ pickUpDate }} {{ pickUpTime }}
    li.model-char-list-item(v-if="hasDeliveryTime")
      span {{ deliveryTimeText }}
      span
        | {{ deliveryDaysCountFrom.number }}&nbsp;
        | {{ deliveryDaysCountFrom.text.trim() === deliveryDaysCountTo.text.trim() ? '' : deliveryDaysCountFrom.text }} -
        | {{ deliveryDaysCountTo.number }}&nbsp;
        | {{ deliveryDaysCountTo.text }}
  template(v-if="accessories.length")
    .step-subtitle.m-t-20 {{ $t("info.optional") }}
    ul.model-char-list.m-t-10
      li.model-char-list-item(v-for="(orAccess, index) in accessories")
        span {{ accessor(orAccess).name }}
        span {{ accessor(orAccess).calculated_price }} {{ currencySymbol }}/{{ $t('mon') }}

  template(v-if="hasNetPrices")
    .step-subtitle.m-t-20 {{ $t("info.financialStructure") }}
    ul.model-char-list.m-t-10
      li.model-char-list-item
        span {{ $t("info.totalPriceIncludedOpt") }}
        span {{ formatPrice(netPriceInclAccessories) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.cashBet") }}
        span {{ formatPrice(cashPayment) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.fundedAmount") }}
        span {{ formatPrice(totalCreditAmount) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.residualVal") }}
        span {{ formatPrice(residualValue) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.interestRate") }}
        span {{ rate }} %
      //- li.model-char-list-item
      //-   span Uppläggningsavgift
      //-   span
      //- li.model-char-list-item
      //-   span Aviavgift
      //-   span

  //- PRICES FOR VAT CREDIT

  template(v-if="isVatCredit")
    .step-subtitle.m-t-20 {{ $t("info.financialArrangements") }}
    ul.model-char-list.m-t-10
      li.model-char-list-item
        span {{ $t("info.totalPriceIncludedOpt") }}
        span {{ formatPrice(netPriceInclAccessories) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.cashBet") }}
        span {{ formatPrice(cashPayment) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.loadAmount") }}
        span {{ formatPrice(totalCreditAmount) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.totalAmount") }}
        span {{ formatPrice(totalVat) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.residualVal") }}
        span {{ formatPrice(residualValue) }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.interestRate") }}
        span {{ rate }} %
      li.model-char-list-item
        span {{ $t("info.steps.effectiveRate.title") }}
        span {{ effectiveRate }} %
      li.model-char-list-item
        span {{ $t("info.contractDuration") }}
        span {{ month }} {{ $t("months") }}
      li.model-char-list-item
        span {{ $t("info.cost") }}, {{ $t("month") }} 1-2
        span {{ month12 }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.cost") }}, {{ $t("month") }} 3
        span {{ month3 }} {{ currencySymbol }}
      li.model-char-list-item
        span {{ $t("info.cost") }}, {{ $t("month") }} 4-36
        span {{ month436 }} {{ currencySymbol }}

  template(v-if="settingsTradeIn.active && isTradeIn && Object.keys(tradeIn).length")
    .step-subtitle.m-t-20 {{ $t("info.steps.tradest.title") }}
    ul.model-char-list.m-t-10
      li.model-char-list-item
        span {{ settingsTradeIn.registration_number }}
        span {{ tradeIn.registration_number || '-' }}
      li.model-char-list-item
        span {{ settingsTradeIn.mileage }}
        span {{ formatPrice(tradeIn.mileage) }}
      li.model-char-list-item
        span {{ settingsTradeIn.value }}
        span {{ formatPrice(tradeIn.value) }} {{ currencySymbol }}
      li.model-char-list-item.flex-column
        span
          strong {{ settingsTradeIn.comments }}
        br
        span {{ tradeIn.comments || '-' }}
</template>

<script>
import { mapState, mapGetters, } from 'vuex'

import { getDaysOrWeeks, } from '@/helpers/helpers.js';

import { formatPrice, isRental, } from '@/helpers/helpers'

import moment from 'moment';

export default {
  computed: {
    ...mapGetters('financing', [
        'residualValue',
        'cashPayment',
        'netPriceInclAccessories',
        'totalCreditAmount',
        'totalLeasePayment'
    ]),
    ...mapState('product', [
      'vehicle',
      'vehicleColor',
      'priceForm',
      'currentMonthlyPrice'
    ]),
    ...mapState('order', [
      'accessories',
      'locationId',
      'residual',
      'pickUpTime',
      'tradeIn',
      'isTradeIn',
    ]),
    ...mapGetters('product', [
      'getPriceForm',
      'getDefaults',
      'deliveryDaysCountFrom',
      'deliveryDaysCountTo',

    ]),
    ...mapState('reseller', {
      hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
      settingsTradeIn: state => state.resellerInfo.settings?.tradeIn,
      deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
      distanceSymbol: state => state.resellerInfo?.market?.distance_measure.symbol || 'mil',
      country: state => state.resellerInfo?.market?.country || {},
      market: state => state.resellerInfo?.market || {},
    }),
    pickUpDate: {
      get() {
        return moment(this.$store.state.order.pickUpDate).format('DD MMM Y')
      },
    },
    month() {
      return this.getPriceForm in this.getDefaults ? this.getDefaults[this.getPriceForm].months : 0
    },
    distance() {
      return this.getPriceForm in this.getDefaults ? this.getDefaults[this.getPriceForm].distance : 0
    },
    fullVehicleName() {
      return `${this.vehicle.brand} ${this.vehicle.model} ${this.vehicle.grade} ${this.vehicle.name}`
    },
    localAccessories() {
      if (!this.vehicle.accessories) return [];

      return this.vehicle.accessories.data;
    },
    location() {
      return this.vehicle.locations?.data.find(item => item.id === this.locationId) || {}
    },
    hasNetPrices() {
      return ['Down payment', 'Corporate leasing'].includes(this.priceForm)
    },
    rate() {
      return Number.parseFloat(this.isHasPriceData('rate')).toFixed(2);
    },

    netPrice() {
      return this.isHasPriceData('net');
    },
    rspPrice() {
      return this.isHasPriceData('rsp');
    },
    overMilesPrice() {
      return this.isHasPriceData('over_miles')
    },
    service() {
      return this.isHasPriceData('service');
    },
    deliveryCost() {
      return this.isHasPriceData('delivery_cost');
    },
    nonRefundableCashPayment() {
      return this.vehicle.nonRefundableCashPayment
    },
    halfYearTaxFee() {
      return this.isHasPriceData('half_year_tax_fee');
    },
    distanceTitle() {
      return ['Private rental', 'Corporate rental'].includes(this.getPriceForm) ?
        `${this.distanceSymbol}/${this.$t('mon')}` : `${this.distanceSymbol}/${this.$t('year')}`
    },
    totalAccessoriesVatPrice() {
      const totalVatPrice = this.vehicle.accessories.data.reduce(
        (sum, next) => {
          if (this.accessories.includes(next.id)) {
            return sum + next.vat
          } else {
            return sum
          }
        },
        0
      )

      return totalVatPrice
    },
    totalColorVatPrice() {
      const totalVatPrice =
        this.vehicleColor?.price?.vat || 0

      return totalVatPrice
    },
    isVatCredit() {
      return ['VAT credit'].includes(this.finance_form_name)
    },
    month12() {
      return this.vehicle?.paymentInfo ? this.vehicle.paymentInfo['1'] : 0
    },
    month3() {
      return this.vehicle?.paymentInfo ? this.vehicle.paymentInfo['3'] : 0
    },
    month436() {
      return this.vehicle?.paymentInfo ? this.vehicle.paymentInfo['4'] : 0
    },
    totalVat() {
      return (
        this.month3 -
        this.month12 +
        this.totalAccessoriesVatPrice +
        this.totalColorVatPrice
      )
    },
    effectiveRate() {
      return this.vehicle.effectiveInterest
    },
    downPaymentDefault() {
      return this.getDefaults[this.getPriceForm]?.['downPayment'] || 0;
    },
  },
  methods: {
    formatPrice,
    isRental,
    getDays(days, flag) {
      const daysParam = getDaysOrWeeks(days, flag, this.market.lang);

      return `${daysParam.number} ${daysParam.text}`;
    },
    accessor(item) {
      return this.localAccessories.find(accessor => accessor.id === item) || {}
    },
    isHasPriceData(key) {
      if (!(this.vehicle.prices && this.vehicle.prices.data.length)) return;

      const pricesObj = this.vehicle.prices.data.find(item => item.finance_form === this.getPriceForm);

      return pricesObj ? pricesObj[key] : 0
    },
  }
}
</script>
